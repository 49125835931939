import React from 'react';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { green, red } from '@mui/material/colors';
import { CompanyCell, SwitchComponent, AddOrEditWhitelistCell } from '../components/cells';

const whitelistDefaultColumns = [
  {
    field: 'company',
    headerName: 'Empresa',
    type: 'string',
    flex: 1.5,
    renderCell: ({ row }) => <CompanyCell company={row} showBlacklist showDebtsSummary />,
  },
  {
    field: 'emitter',
    headerName: 'Emisor',
    type: 'string',
    flex: 1.5,
    renderCell: ({ row }) => {
      if (!row.emitter) return <Typography>Sin emisor</Typography>;
      return (<CompanyCell company={row.emitter} />);
    },
  },
  {
    field: 'receiver',
    headerName: 'Receptor',
    type: 'string',
    flex: 1.5,
    renderCell: ({ row }) => {
      if (!row.receiver) return <Typography>Sin receptor</Typography>;
      return (<CompanyCell company={row.receiver} />);
    },
  },
  {
    field: 'monthlyRate',
    headerName: 'Tasa',
    type: 'number',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <Typography variant="body2">{`${row.monthlyRate?.toFixed(2)?.replace('.', ',')}%`}</Typography>
    ),
  },
  {
    field: 'defaultRate',
    headerName: 'Mora',
    type: 'number',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <Typography variant="body2">{`${row.defaultRate?.toFixed(2)?.replace('.', ',')}%`}</Typography>
    ),
  },
  {
    field: 'retentionRate',
    headerName: 'Retención',
    type: 'number',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <Typography variant="body2">{`${row.retentionRate?.toFixed(2)?.replace('.', ',')}%`}</Typography>
    ),
  },
  {
    field: 'active',
    headerName: 'Activo',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <Icon sx={{ color: row.active ? green[700] : red[700] }}>
        <FiberManualRecord />
      </Icon>
    ),
  },
  {
    field: 'switchComponent',
    headerName: 'Acción',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => (
      <SwitchComponent id={row.id} initialState={row.active} />
    ),
  },
  {
    field: 'editComponent',
    headerName: 'Editar',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 0.4,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => <AddOrEditWhitelistCell whitelist={row} />,
  },
];

export default whitelistDefaultColumns;
