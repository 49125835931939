import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  TimeInStageCell,
  ActionDrawerCell,
  CompanyCell,
  PurchaseOrderNumberCell,
} from '../components/cells';
import {
  AvatarCell,
  CollectionPriorityCell,
  DateWithDaysDiffCell,
} from '../components/dataGridCells';
import CollectionActions from '../components/drawer/CollectionActions';
import DrawerHeader from '../components/drawer/DrawerHeader';
import OrderingStatus from '../components/status/OrderingStatus';
import TooltipTypography from '../components/typography/TooltipTypography';
import {
  formatDayMonthYear,
  formatMoney,
  formatRut,
  getTimeDiffText,
  orderingStatusToSpanish,
} from '../helpers';
import { useIsMobile } from '../hooks';
import useWidth from './document-columns-width';
import CompanyBlacklistComponent from '../components/cells/CompanyBlacklistComponent';
import CompanyDebtsSummary from '../components/cells/CompanyDebtsSummary';
import dayjs from '../config/dayjs';

const useOrderingLucilaPreColumns = (customOrderingColumns = []) => {
  const {
    rateWidth,
    amountWidth,
    dateWidth,
    orderNumberWidth,
    littleMessageStatus,
  } = useWidth();
  const isMobile = useIsMobile();
  const orderingLucilaPreColumns = [
    {
      field: 'orderNumber',
      headerName: 'Número',
      width: orderNumberWidth,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => (
        <PurchaseOrderNumberCell
          purchaseOrderId={row.id}
          orderNumber={row.orderNumber}
        />
      ),
    },
    {
      field: 'company_Name',
      type: 'string',
      headerName: 'Empresa',
      renderCell: ({ row }) => <CompanyCell company={row?.company.masterEntity} />,
      sortable: true,
      flex: 2,
    },
    {
      field: 'company_MasterEntity_Name',
      headerName: 'Cliente',
      flex: 1,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            direction="row"
          >
            <MuiLink
              component={Link}
              to={`/app/ordering-debtor/${row.company?.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {row.company?.masterEntity.name}
            </MuiLink>
            <CompanyBlacklistComponent
              masterEntity={row.company?.masterEntity}
              disableDisplay
            />
            <CompanyDebtsSummary masterEntity={row.company?.masterEntity} />
          </Stack>
          {!isMobile && (
            <Typography variant="subtitle1">
              {formatRut(row.company?.rut)}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'purchaser_Name',
      headerName: 'Comprador',
      flex: 1.3,
      filterable: false,
      sortable: true,
      renderCell: ({ row }) => <CompanyCell company={row.purchaser} showBlacklist showDebtsSummary type="receiver" />,
    },
    {
      field: 'orderingoffer_OrderingFinancedAmount',
      headerName: 'Monto financiado',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <Grid width="100%" alignItems="flex-start" justifyContent="center">
          <Typography variant="body2">
            $
            {formatMoney(params.row.orderingoffer?.orderingFinancedAmount || 0)}
          </Typography>
          <Typography variant="caption" width="inherit">
            Mora: ${formatMoney(params.row.orderingDebt?.debt || 0)}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'publicationDate',
      headerName: 'Fecha OC',
      width: dateWidth,
      filterable: false,
      sortable: true,
      renderCell: (params) => (
        <Typography variant="body2">
          {formatDayMonthYear(params.row.publicationDate)}
        </Typography>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Monto total',
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: true,
      width: amountWidth,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.totalAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          ${formatMoney(params.row.orderingsimulation.orderingPaymentAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.orderingsimulation.orderingMonthlyRate
            .toFixed(2)
            .replace('.', ',')}
          %
        </Typography>
      ),
    },
    {
      field: 'orderingsimulation_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {(100 - params.row.orderingsimulation.orderingRetentionRate)
            .toFixed(2)
            .replace('.', ',')}
          %
        </Typography>
      ),
    },
    {
      field: 'orderingStatus',
      headerName: 'Estado',
      width: littleMessageStatus,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body" noWrap>
          {orderingStatusToSpanish[params.row.status]}
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_OrderingPaymentAmount',
      headerName: 'Monto adelanto',
      width: 110,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          ${formatMoney(row.orderingoffer.orderingPaymentAmount)}
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_OrderingMonthlyRate',
      headerName: 'Tasa',
      width: rateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.orderingoffer.orderingMonthlyRate
            .toFixed(2)
            .replace('.', ',')}
          %
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_OrderingRetentionRate',
      headerName: 'Anticipo',
      width: rateWidth + 5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {(100 - row.orderingoffer.orderingRetentionRate)
            .toFixed(2)
            .replace('.', ',')}
          %
        </Typography>
      ),
    },
    {
      field: 'orderingoffer_InvoiceIssuedDate',
      headerName: 'Emisión factura',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: (params) => (
        <DateWithDaysDiffCell
          date={params.row.orderingoffer.invoiceIssuedDate}
          useColors
        />
      ),
    },
    {
      field: 'orderingoffer_InvoiceDateToPay',
      headerName: 'Pago factura',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          overflow="hidden"
          direction="column"
        >
          <Typography variant="body1">
            {dayjs(row.orderingoffer.invoiceDateToPay).format('DD-MM-YYYY')}
          </Typography>
          <Typography variant="caption">
            {getTimeDiffText(dayjs(row.orderingoffer.invoiceDateToPay))}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'orderingsimulation_InvoiceIssuedDate',
      headerName: 'Emisión factura',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          overflow="hidden"
          direction="column"
        >
          <Typography variant="body1">
            {dayjs(row.orderingsimulation.invoiceIssuedDate).format(
              'DD-MM-YYYY',
            )}
          </Typography>
          <Typography variant="caption">
            {getTimeDiffText(dayjs(row.orderingsimulation.invoiceIssuedDate))}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'orderingsimulation_InvoiceDateToPay',
      headerName: 'Fecha Acordada de Pago',
      width: dateWidth,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      renderCell: ({ row }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          overflow="hidden"
          direction="column"
        >
          <Typography variant="body1">
            {dayjs(row.orderingsimulation.invoiceDateToPay).format(
              'DD-MM-YYYY',
            )}
          </Typography>
          <Typography variant="caption">
            {getTimeDiffText(dayjs(row.orderingsimulation.invoiceDateToPay))}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'agreedDateToPay',
      headerName: 'Fecha de pago',
      sortable: false,
      filterable: true,
      width: 150,
      valueGetter: (params) => params.row.collectionManager.currentDataForCollection?.dateToPay,
      renderCell: (params) => (
        <DateWithDaysDiffCell
          date={
            params.row.collectionManager.currentDataForCollection?.dateToPay
          }
          useColors
        />
      ),
    },
    {
      field: 'collectionManagerRelation_Collector',
      headerName: 'Cobradora',
      filterable: false,
      sortable: false,
      maxWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <AvatarCell user={params.row.collectionManager.collector} />
      ),
    },
    {
      field: 'executiveAssigned',
      headerName: 'Ejecutivo',
      filterable: true,
      valueGetter: (params) => params.row.company.executiveAssigned?.firstName,
      renderCell: (params) => (
        <AvatarCell user={params.row.company.executiveAssigned} />
      ),
    },
    {
      field: 'collectionManagerRelation_CollectionPriority_Value',
      headerName: 'Prioridad',
      sortable: true,
      filterable: true,
      valueGetter: (params) => params.row.collectionManager.collectionPriority.value,
      renderCell: (params) => (
        <CollectionPriorityCell
          priority={params.row.collectionManager.collectionPriority.value}
        />
      ),
    },
    {
      field: 'actionsDrawer',
      headerName: 'Gestiones',
      type: 'string',
      minWidth: 90,
      flex: 0.5,
      renderCell: (params) => (
        <ActionDrawerCell
          title={params.row.company.masterEntity.name}
          subtitle={params.row.company.masterEntity.displayNationalIdentifier}
          actions={params.row.collectionManager?.actions ?? []}
          headerComponent={DrawerHeader}
          lastActionDate
          contentComponent={CollectionActions}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Estado',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => <OrderingStatus status={row.status} />,
    },
    {
      field:
        'orderingsimulation_EvaluationRequest_evaluationAnalystDescription',
      headerName: 'Comentario evaluación',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => (
        <TooltipTypography variabt="body1" noWrap>
          {row.orderingsimulation?.evaluationRequest
            ?.evaluationAnalystDescription
            || row.orderingsimulation?.evaluationRequest?.finalAnalystDescription}
        </TooltipTypography>
      ),
    },
    {
      field: 'invoiceMoneyTransfer_createdAt',
      headerName: 'Tiempo en Estado',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => (
        <TimeInStageCell time={row.invoiceMoneyTransfer.createdAt} />
      ),
    },
  ];
  const orderingPreColumns = orderingLucilaPreColumns.map(
    (defaultColumn) => customOrderingColumns.find(
      (customColumn) => customColumn.field === defaultColumn.field,
    ) || defaultColumn,
  );
  const newPreColumns = customOrderingColumns.filter(
    (customColumn) => !orderingLucilaPreColumns
      .map((defaultColumn) => defaultColumn.field)
      .includes(customColumn.field),
  );
  return [...orderingPreColumns, ...newPreColumns];
};

export default useOrderingLucilaPreColumns;
